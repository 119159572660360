@keyframes pulse {
    0% {
        background-color: rgb(1, 97, 129);
    }
    50% {
        background-color: rgb(1, 67, 89);
    }
    100% {
        background-color: rgb(1, 97, 129);
    }
}

.jumbotron,
.jumbotron-med,
.jumbotron-mission {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: white;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    animation: pulse 5s infinite;
}

.jumbotron {
    flex-direction: row;
    padding-top: 5rem; 
    background-color: rgb(1, 97, 129);
}

.jumbotron-med {
    flex-direction: row;
    padding: 100px; 
    background-color: rgb(1, 97, 129);
}

.jumbotron-aboutUs {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: rgb(1, 97, 129);
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    background-color: white;
}

.jumbotron-mv {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: white;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    background-color: rgb(1, 97, 129);
    align-items: center;
}

.jumbotron-ourTeam {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;      
    color: rgb(1, 97, 129);
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    flex-direction: row;
    padding: 50px; 
    padding-top: 0px;
    background-color: lightblue;
}

.heading,
.heading-med {
    flex: 2;
    justify-content: center;
    padding: 0.5rem;
    color: inherit;
    font-size: 2rem;
    font-weight: 200;
    box-sizing: border-box;
}

.heading {
    margin-left: 4rem;
    margin-top: 4rem;
}

.heading-med {
    margin-top: 0px;
}

.content {
    margin-top: 1.25rem;
    font-size: 1rem;
    box-sizing: border-box;
}

.youtube-player {
    margin-top: 5rem;
    margin-right: 1.25rem;
    box-sizing: border-box;
}

.youtube-player-mv {
    margin-top: 5rem;
    margin-right: 1rem;
    padding-left: 3rem;
    box-sizing: border-box;
}

.youtube-player-mission {
    margin-top: 1.5rem;
    margin-right: 1.25rem;
    margin-left: 6rem;
    box-sizing: border-box;
}

.heading-mission {
    flex: 2;
    justify-content: center;
    padding: 0.5rem;
    color: inherit;
    margin-left: 0.625rem;
    font-size: 2rem;
    font-weight: 300;
    box-sizing: border-box;
}

.jumbotron-mission {
    flex-direction: row;
    background-color: rgb(26, 176, 227);
    padding-left: 0;
    padding-right: 0;
    padding-top: 5rem;
}

@media (max-width: 768px) {
    .jumbotron,
    .jumbotron-aboutUs,
    .jumbotron-mv,
    .jumbotron-ourTeam,
    .jumbotron-med {
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    .heading, .content, .youtube-player, .youtube-player-mission, .heading-mission, .youtube-player-mv {
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: center;
    }
}
