@keyframes pulse {
  0% {
      background-color: rgb(1, 97, 129);
  }
  50% {
      background-color: rgb(1, 67, 89);
  }
  100% {
      background-color: rgb(1, 97, 129);
  }
}

body {
  background-color: rgb(1, 97, 129);
}

/* General Navbar Styles */
.navbar,
.navbar-styles {
  height: 100px;
  background-color: rgb(1, 97, 129) !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  animation: pulse 5s infinite; 
}

.navbar-brand,
.navbar-toggler,
.navbar-nav,
.custom-nav-link {
  height: 100px;
  display: flex;
  align-items: center; /* Center items vertically */
}

/* Navbar Brand & Logo Styles */
.madas-health-logo {
  height: 60px;
  margin-right: 10px;
}

.logo-label {
  color: white;
  font-size: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 10px;
}

/* Navbar Link Styles */
.custom-nav-link {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 15px;
  margin-left: 15px;
  margin-right: 15px;
  color: white !important;
  width: 100%;
}

.navbar-collapse .nav-link {
  background-color: rgb(1, 97, 129) !important;  
}

/* Navbar Toggler (Hamburger Button) Styles */
.navbar-toggler {
  border: none; /* Removes the border */
  background-color: transparent !important; /* Ensures the button background remains consistent */
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  border: none;
  outline: none;
  box-shadow: none; /* Removes any potential shadows on focus/hover */
}

.dropdown-icon {
  padding-left: 5px;
}

.nav-item {
  position: relative;
  display: inline-block;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.nav-item:hover .dropdown {
  display: block;
}

/* Adjustments for Smaller Screens */
@media screen and (max-width: 576px) {
  .madas-health-logo {
      height: 40px;
  }

  .logo-label {
      font-size: 20px;
  }

  .custom-nav-link {
      font-size: 14px;
  }

  .navbar,
  .navbar-styles,
  .navbar-brand,
  .navbar-toggler,
  .navbar-nav {
      height: auto;
  }

  .navbar-nav {
    justify-content: flex-start; /* This aligns the navbar items to the left */
}

  .navbar-nav .nav-item {
      align-items: flex-start; /* This aligns each individual navbar item to the left */
  }

  .navbar-collapse {
    background-color: rgb(1, 97, 129) !important; 
    text-align: left;   /* align text to the left */
  }

  .navbar-nav .nav-item {
    display: block;     /* stack items vertically */
    width: 100%;        /* occupy the full width */
    text-align: left;   /* align text to the left */
  }
  
  .navbar-nav {
    flex-direction: column; /* stack items vertically */
    width: 100%;            /* occupy the full width */
  }
}
